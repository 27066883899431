import Vue from "vue";
import Vuex from "vuex";
Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    visibility: 1,
    drawer: false,
    loadingRoute: false,
    mobile: false,
    path: null,
    user: null,
    manualGeneralAdminWhitelabel: null,
    eventSelected: null,
    domain: null,
  },
  mutations: {
    setVisibility: (state, data) => {
      state.visibility = data;
    },
    setUser: (state, data) => {
      state.user = data;
    },
    setEventSelected: (state, data) => {
      state.eventSelected = data;
    },
    setDrawer: (state, data) => {
      state.drawer = data;
    },
    setLoadingRoute: (state, bool) => {
      state.loadingRoute = bool;
    },
    setMobile: (state, bool) => {
      state.mobile = bool;
    },
    setPath: (state, data) => {
      state.path = data;
    },
    setManualGeneralAdminWhitelabel: (state, data) => {
      state.manualGeneralAdminWhitelabel = data;
    },
    setDomain: (state, data) => {
      state.domain = data;
    }
  },
});
