<template>
  <v-app>
    <v-main>
      <router-view></router-view>
      <FooterComponent />
    </v-main>
  </v-app>
</template>

<script>
import FooterComponent from './components/FooterComponent.vue';
export default {
  name: 'App',
  components: { FooterComponent },

  data: () => ({
    
  }),
};
</script>
