// import Vue from "vue";
// import axios from "axios";
// import store from "./store";
import Router from "vue-router";

const routes = [
  {
    path: "/",
    component: () => import("./pages/home.vue"),
    name: "Home",
  },
  {
    path: "/confirm",
    component: () => import("./pages/confirm.vue"),
    name: "Confirm",
  },
  {
    path: "/finished",
    component: () => import("./pages/finished.vue"),
    name: "Finished",
  },
  {
    path: "/pix",
    component: () => import("./pages/pix.vue"),
    
  },
];

const router = new Router({
  history: true,
  mode: "history",
  routes,
  scrollBehavior() {
    return { x: 0, y: 0 };
  },
});

export default router;
