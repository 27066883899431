<template>
  <v-container>
    <div class="footer_fantasma"></div>
    <div class="footer">
      <div class="footer_parceria">
        <p class="subtitulo_footer">Parceria:</p>
        <br />
        <v-flex class="btn_logos">
          <v-row class="justify-start align-baseline">
            <v-col md="4">
              <img
                class="img_logo_vou_de_meia"
                src="./../../public/imgs/logos/logo_colorido.png"
                alt="Logo tipo do Vou de Meia Colorido "
              />
            </v-col>
            <v-spacer></v-spacer>
          </v-row>
        </v-flex>
      </div>
      <div class="footer_app">
        <p>
          <a href="https://novosite.voudemeia.com.br/termos" class="link-politica" target="_blank">
            Política de Privacidade / Termos de Uso
          </a>
        </p>
        <p class="subtitulo_footer">Baixe o app da Vou de Meia:</p>
        <v-flex class="btn_app">
          <v-row class="justify-start">
            <v-col md="4">
              <a
                href="https://apps.apple.com/br/app/vou-de-meia/id1501747517"
                target="_blank"
                class="link"
              >
                <img
                  class="img_mobile"
                  src="./../../public/imgs/btns/btn_apple.png"
                  alt="botão da loja de aplicativos da apple "
                />
              </a>
            </v-col>
            <v-col md="4">
              <a
                href="https://play.google.com/store/apps/details?id=br.com.voudemeia.app"
                class="link"
                target="_blank"
              >
                <img
                  class="img_mobile"
                  src="./../../public/imgs/btns/btn_google_play.png"
                  alt="botão da loja de aplicativos da google play"
                />
              </a>
            </v-col>
          </v-row>
        </v-flex>
      </div>
      <v-spacer></v-spacer>
    </div>

    <div class="footer_copy">
      <p>Copyright © 2023. Todos os Direitos Reservados</p>

      <p>Desenvolvido por HoomWeb</p>
    </div>
  </v-container>
</template>
<script>
export default {
  name: 'FooterComponent',
  props: {},
};
</script>

<style>
.footer_fantasma {
  position: absolute;
  width: 90000vw;
  left: -500vw;
  z-index: 0;
  height: 200px;
  background-color: #fff;
}
a.link-politica {
  color: #000 !important;
  text-decoration: underline;
}
.footer {
  position: relative;
  z-index: 1;
  padding: 20px 0px;
  height: 200px;
  background-color: #fff;
  display: flex;
}
.footer_parceria {
  width: 400px;
}
.footer_app {
  width: 500px;
}
.img_logo_vou_de_meia {
  width: 207px;
  height: 64px;
}
.img_logo_abe {
  width: 90px;
}
.subtitulo_footer {
  font-family: 'Calibri';
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 22px;
  color: #000000;
  flex: none;
  order: 0;
  flex-grow: 0;
}
.footer_copy {
  margin: 15px 0 0 0;
  display: flex;
  justify-content: space-between;
}
.footer_copy p {
  font-family: 'Calibri';
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 22px;
  text-align: center;
  color: #ffffff;
}
@media screen and (orientation: portrait), (max-width: 1000px) {
  .footer_fantasma {
    height: 265px;
  }
  .footer,
  .footer_copy {
    margin: 15px 0;
    height: auto;
    /* background-color: aqua; */
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
  .footer_parceria,
  .footer_app {
    width: 80vw;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
  .img_mobile {
    width: 20vw;
  }
  .img_logo_vou_de_meia {
    max-width: 40vw;
  }
}
</style>
