import Vue from 'vue'
import App from './App.vue'
// import "es6-promise/auto";
import 'core-js/es/array';
import VueRouter from "vue-router";
import axios from "axios";
import Notifications from "vue-notification";
import DatetimePicker from "vuetify-datetime-picker";
import Vuetify from "vuetify";
import "vuetify/dist/vuetify.min.css";
import pt from "vuetify/src/locale/pt.ts";
import router from "./router";
import store from "./store";
import VueSplide from '@splidejs/vue-splide';
// import '@splidejs/splide/dist/css/themes/splide-default.min.css';

// axios.defaults.baseURL = `http://localhost:3000/api`;
axios.defaults.baseURL = `https://api.parceiros.voudemeia.com.br/api`;
axios.defaults.headers.common = {
  Accept: "application/json",
  // Authorization: `Bearer ${localStorage.getItem("tk")}`,
};

Vue.prototype.$http = axios;
Vue.router = router;
Vue.use(VueRouter);
Vue.use(Notifications);
Vue.use(DatetimePicker);
Vue.use(VueSplide);

export default new Vuetify({
  lang: {
    locales: { pt },
    current: "pt",
  },
});

Vue.use(Vuetify);
Vue.config.productionTip = false;

new Vue({
  render: (h) => h(App),
  store,
  router,
  vuetify: new Vuetify(),
}).$mount("#app");